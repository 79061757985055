import {
  IAwardResponse, IEntityPage,
  IStrapiAttributes,
  IStrapiData,
} from '@redux/types';
import {call, put, takeEvery} from 'redux-saga/effects';
import api from '@api/index';
import * as actions from './actions';
import {EAwardActionType, IAwardPageGetAction} from '@redux/awards/types';
import {generateAwardsGrid, generateStrapiQuery} from '@common/functions';


function* getAwardsPage({payload}: IAwardPageGetAction) {
  try {
    const {locale} = payload
    const queryPage = generateStrapiQuery({populate: 'deep', locale })
    const queryAward = generateStrapiQuery({
      pagination: {
        page: 1,
        pageSize: 100,
      },
      sort: [{
        key: 'date',
        direction: 'desc'
      }],
      locale
    })
    const page: IStrapiData<IStrapiAttributes<IEntityPage>> = yield call(() => api.get('/awards-page', {query: queryPage}))
    const response: IStrapiData<IStrapiAttributes<IAwardResponse>[]> = yield call(() => api.get('/awards', {query: queryAward}))
    const data: IAwardResponse[] = response.data.map(d => d.attributes)
    const {awards, years} = generateAwardsGrid(data)
    yield put(actions.setAwards({
      page: page.data.attributes,
      awards,
      years
    }))
  } catch (e) {
    console.log('Fetch failed, reason: ', e)
  }
}

export default [
  takeEvery(EAwardActionType.GET_AWARDS_PAGE, getAwardsPage),
]
