import {
  CardType,
  ISolutionDetail,
  ISolutionDetailResponse,
  IStep,
  IStrapiAttributes,
  IStrapiData
} from '@redux/types';
import {call, put, takeEvery} from 'redux-saga/effects';
import api from '@api/index';
import * as actions from './actions';
import {ESolutionActionType, ISolutionDetailGetAction} from '@redux/solutions/types';
import {
  generateStep,
  generateStrapiQuery
} from '@common/functions';


function* getSolution({payload}: ISolutionDetailGetAction) {
  const query = generateStrapiQuery({
    //fields,
    populate: 'deep,4',
    locale: payload?.locale || 'ru'
  })
  try {
    const response: IStrapiData<IStrapiAttributes<ISolutionDetailResponse>> = yield call(() => api.get(`/solutions/${payload.slug}`, {
      query
    }))
    const data = response.data.attributes
    const steps: IStep[] = generateStep(data.steps)
    let solution: ISolutionDetail = {
      ...data,
      detail: {
        ...data.detail,
        cards: data.detail.cards.map(card => {
          const cardType = card?.cardType?.data?.attributes?.type
          return {
            ...card,
            cardType: cardType ? cardType as CardType : null
          }
        }) || [],
      },
      steps
    }
    yield put(actions.setSolutions({
      solution
    }))
  } catch (e) {
    yield put(actions.setSolutions({
      solution: null
    }))
    console.log('Fetch failed, reason: ', e)
  }
}

export default [
  takeEvery(ESolutionActionType.GET_SOLUTION, getSolution),
]
