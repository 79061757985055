import {IDetail, IEntityPage, ILocale, IMetadata, IServiceDetail, IServicePage} from '@redux/types';

export enum EServiceActionType {
  GET_SERVICES_PAGE = 'GET_SERVICES_PAGE',
  GET_SERVICE = 'GET_SERVICE',
  SET_SERVICES = 'SET_SERVICES',
}

export interface IServiceState {
  page?: IEntityPage
  servicePage?: IServicePage | null
  service?: IServiceDetail | null
}

export interface IServiceSetAction {
  type: EServiceActionType.SET_SERVICES
  payload: IServiceState
}

export interface IServicePageGetAction {
  type: EServiceActionType.GET_SERVICES_PAGE
  payload: ILocale
}

export interface IServiceDetailGetAction {
  type: EServiceActionType.GET_SERVICE
  payload: IDetail
}

export type IServiceActions = IServiceSetAction | IServicePageGetAction | IServiceDetailGetAction
