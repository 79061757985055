import {
  EProjectActionType, IProjectGetAction,
  IProjectsGetAction,
  IProjectsPageGetAction,
  IProjectsSetAction,
  IProjectsState, ISelectProjectsTagAction
} from '@redux/projects/types';
import {IDetail, ILocale} from '@redux/types';


export const getProjectsPage = (payload: ILocale): IProjectsPageGetAction => ({
  type: EProjectActionType.GET_PROJECTS_PAGE,
  payload
})

export const getProjects = (payload: ILocale): IProjectsGetAction => ({
  type: EProjectActionType.GET_PROJECTS,
  payload
})

export const getProject = (payload: IDetail): IProjectGetAction => ({
  type: EProjectActionType.GET_PROJECT,
  payload: payload
})

export const selectProjectTag = (payload: string | null): ISelectProjectsTagAction => ({
  type: EProjectActionType.SELECT_TAG,
  payload
})

export const setProjects = (payload: IProjectsState): IProjectsSetAction => ({
  type: EProjectActionType.SET_PROJECTS,
  payload
})

