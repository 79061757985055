import {IBlog, IDetail, IEntityPage, ILocale, ITag} from '@redux/types';

export enum EBlogsActionType {
  SET_BLOGS = 'SET_BLOGS',
  GET_BLOG_PAGE = 'GET_BLOG_PAGE',
  GET_BLOG = 'GET_BLOG'
}

export interface IBlogState {
  page?: IEntityPage | null
  tags?: ITag[]
  blogs?: IBlog[]
  blog?: IBlog | null
  selectedTagSlug?: string | null
}

export interface IBlogsSetAction {
  type: EBlogsActionType.SET_BLOGS
  payload: IBlogState
}

export interface IBlogPageGetAction {
  type: EBlogsActionType.GET_BLOG_PAGE
}

export interface IBlogGetAction {
  type: EBlogsActionType.GET_BLOG
  payload: IDetail
}

export type IBlogsActions = IBlogsSetAction | IBlogPageGetAction | IBlogGetAction
