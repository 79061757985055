import {
  IAppSettings,
  IPersonalInformation,
  IPersonalInformationResponse,
  ISection,
  IStrapiAttributes,
  IStrapiData,
} from '@redux/types';
import {call, delay, put, takeEvery} from 'redux-saga/effects';
import api from '@api/index';
import * as actions from './actions';
import {EAppActionType, IAppSettingsGetAction, IAppSendRequestFormPostAction, IAppState} from '@redux/app/types';
import {generatePersonalInformationText, generateStrapiQuery, getRequestFormData} from '@common/functions';
import {REQUEST_FORM_DELAY} from '@common/constants';


function* getPersonalInformation() {
  try {
    const response: IStrapiData<IStrapiAttributes<IPersonalInformationResponse>> = yield call(() => api.get('/privacy-page', {
      query: {
        populate: '*'
      }
    }))
    const data = response.data.attributes
    const personalInformation: IPersonalInformation = {
      title: data.title,
      agreement: {
        title: generatePersonalInformationText(data.agreement.title[0]),
        description: generatePersonalInformationText(data.agreement.description[0])
      },
      conditions: data.conditions.map(d => generatePersonalInformationText(d, 'simple-link simple-link--no-color simple-link--no-underline'))
    }
    yield put(actions.setApp({
      personalInformation
    }))
  } catch (e) {
    console.log('Fetch privacy failed, reason: ', e)
  }
}

function* sendRequestForm({payload}: IAppSendRequestFormPostAction) {
  const steps = payload.type === 'request' ? [
    {requestForm: {open: true, status: 'loading', isClear: false}},
    {requestForm: {open: true, status: 'success', isClear: false}},
    {requestForm: {open: false, status: null, isClear: true}},
    {requestForm: {open: true, status: 'error'}}
  ] : payload.type === 'block' ? [
    {blockForm: {status: 'loading', isClear: false}},
    {blockForm: {status: 'success', isClear: false}},
    {blockForm: {status: null, isClear: true}},
    {blockForm: {status: 'error'}}
  ] : [
    {modalForm: {open: true, form: payload.type, status: 'loading', isClear: false}},
    {modalForm: {open: true, form: payload.type, status: 'success', isClear: false}},
    {modalForm: {open: false, form: payload.type, status: null, isClear: true}},
    {modalForm: {open: true, form: payload.type, status: 'error'}}
  ]
  
  try {
    yield put(actions.setApp({
      ...steps[0] as IAppState
    }))
    const body = getRequestFormData(payload.data)
    yield call(() => api.post(`/common/sendRequestForm`, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      body
    }))
    yield put(actions.setApp({
      ...steps[1] as IAppState
    }))
    yield delay(REQUEST_FORM_DELAY)
    yield put(actions.setApp({
      ...steps[2] as IAppState
    }))
  } catch (e) {
    yield put(actions.setApp({
      ...steps[3] as IAppState
    }))
    console.log('Send form failed, reason: ', e)
  }
}

function* getAppSettings({payload}: IAppSettingsGetAction) {
  try {
    const {locale} = payload
    const query = generateStrapiQuery({
      populate: ['formModal.button', 'formModal.fields'],
      locale
    })
    const response: IStrapiData<IStrapiAttributes<IAppSettings>> = yield call(() => api.get(`/form`, {query}))
    const settings: IAppSettings = response.data.attributes
    yield put(actions.setApp({
      settings: {
        formModal: settings?.formModal && {
          ...settings.formModal,
          fields: settings?.formModal?.fields?.map((f, index) => ({
            ...f,
            key: `field-${index}`
          })) ?? []
        }
      }
    }))
  } catch (e: any) {
    console.log('Fetch modal settings failed, reason: ', e?.error?.status)
  }
}

export default [
  takeEvery(EAppActionType.GET_PERSONAL_INFORMATION, getPersonalInformation),
  takeEvery(EAppActionType.GET_APP_SETTINGS, getAppSettings),
  takeEvery(EAppActionType.SEND_REQUEST_FORM, sendRequestForm),
]
