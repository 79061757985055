import { combineReducers } from "redux";
import { HYDRATE } from "next-redux-wrapper";
import { AppReducer } from "@redux/app/reducer";
import { VacanciesReducer } from "@redux/vacancies/reducer";
import { ProjectsReducer } from "@redux/projects/reducer";
import { ServicesReducer } from "@redux/services/reducer";
import { AwardsReducer } from "@redux/awards/reducer";
import { TagsReducer } from "@redux/tags/reducer";
import { ContactsReducer } from "@redux/contacts/reducer";
import { ReviewsReducer } from "@redux/reviews/reducer";
import { NewsReducer } from "@redux/news/reducer";
import { BlogsReducer } from "@redux/blogs/reducer";
import { HomeReducer } from "@redux/home/reducer";
import { SolutionsReducer } from "@redux/solutions/reducer";
import { PartnersReducer } from "@redux/partners/reducer";

import { isPlainObject, isEmpty } from "lodash";
import { ExpertiseReducer } from "@redux/expertise/reducer";

const rootReducers = combineReducers({
  app: AppReducer,
  awards: AwardsReducer,
  blogs: BlogsReducer,
  contacts: ContactsReducer,
  home: HomeReducer,
  news: NewsReducer,
  projects: ProjectsReducer,
  reviews: ReviewsReducer,
  services: ServicesReducer,
  tags: TagsReducer,
  vacancies: VacanciesReducer,
  solutions: SolutionsReducer,
  partners: PartnersReducer,
  expertise: ExpertiseReducer,
});

const isEmptyValue = (value: any) => {
  return (
    value === null ||
    value === undefined ||
    (typeof value === "number" && isNaN(value)) ||
    (Array.isArray(value) && value.length === 0) ||
    value === ""
  );
};

const deepMerge = (state: any, payload: any) => {
  const nextState = { ...state };

  for (let key in payload) {
    if (Object.prototype.hasOwnProperty.call(payload, key)) {
      const payloadValue = payload[key];
      const stateValue = state?.[key];
      if (payloadValue !== undefined) {
        if (isPlainObject(payloadValue) && !isEmpty(payloadValue)) {
          nextState[key] = deepMerge(stateValue, payloadValue);
        } else if (!isEmptyValue(payloadValue)) {
          nextState[key] = payloadValue;
        }
      }
      if (stateValue === undefined) {
        nextState[key] = payloadValue;
      }
    }
  }
  return nextState;
};

export const reducer = (state: any, action: any) => {
  if (action.type === HYDRATE) {
    // return deepMerge(state, action.payload);
    return deepMerge(state, action.payload);
  }
  return rootReducers(state, action);
};

export type RootState = ReturnType<typeof rootReducers>;
