import { all } from "redux-saga/effects";
import appSagas from "./app/saga";
import awardsSagas from "./awards/saga";
import contactsSagas from "./contacts/saga";
import servicesSagas from "./services/saga";
import vacanciesSagas from "./vacancies/saga";
import projectsSagas from "./projects/saga";
import tagsSagas from "./tags/saga";
import reviewsSagas from "./reviews/saga";
import newsSagas from "./news/saga";
import blogsSagas from "./blogs/saga";
import homeSagas from "./home/saga";
import solutionsSaga from "./solutions/saga";
import partnersSaga from "./partners/saga";
import expertiseSaga from "./expertise/saga";

export default function* rootSaga() {
  yield all([
    ...appSagas,
    ...awardsSagas,
    ...blogsSagas,
    ...contactsSagas,
    ...homeSagas,
    ...newsSagas,
    ...projectsSagas,
    ...reviewsSagas,
    ...servicesSagas,
    ...solutionsSaga,
    ...tagsSagas,
    ...vacanciesSagas,
    ...partnersSaga,
    ...expertiseSaga,
  ]);
}
