import {EServiceActionType, IServiceActions, IServiceDetailGetAction, IServiceState} from '@redux/services/types';
import {IDetail, ILocale} from '@redux/types';
import {TLang} from '@common/types';


export const setServices = (payload: IServiceState): IServiceActions => ({
  type: EServiceActionType.SET_SERVICES,
  payload
})

export const getServicePage = (payload: ILocale): IServiceActions => ({
  type: EServiceActionType.GET_SERVICES_PAGE,
  payload
})

export const getService = (payload: IDetail): IServiceDetailGetAction => ({
  type: EServiceActionType.GET_SERVICE,
  payload
})
