import {
  ILocale, IMetadata,
  IPage,
  IStep,
} from '@redux/types';
import {ICaseListItem} from '@common/types';


export enum EPartnersActionType {
  GET_PARTNERS_PAGE = 'GET_PARTNERS_PAGE',
  SET_PARTNERS_PAGE = 'SET_PARTNERS_PAGE',
}

export type StatisticPartnersType = {
  id: number, 
  ageTitle: string, 
  teemsTitle: string,
}
export interface IPartnersPage extends IPage {
  statistics: StatisticPartnersType,
}

export interface IPartnersState {
  projectsPage?: IPartnersPage | null,
}

export interface IPartnersPageGetAction {
  type: EPartnersActionType.GET_PARTNERS_PAGE,
  payload: ILocale
}

export interface IPartnersPageSetAction {
  type: EPartnersActionType.SET_PARTNERS_PAGE,
  payload: ILocale
}

export type IProjectsActions = IPartnersPageGetAction
