import {IContacts, IEntityPage, ILocale, IMetadata} from '@redux/types';

export enum EContactsActionType {
  GET_CONTACTS = 'GET_CONTACTS',
  SET_CONTACTS = 'SET_CONTACTS',
}

export interface IContactsState {
  page?: IEntityPage
  contacts?: IContacts | null
}

export interface IContactsSetAction {
  type: EContactsActionType.SET_CONTACTS,
  payload: IContactsState
}

export interface IContactsGetAction {
  type: EContactsActionType.GET_CONTACTS
  payload: ILocale
}

export type IContactsActions = IContactsSetAction | IContactsGetAction
