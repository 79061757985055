import {
  CardType, IProjectDetail, IProjectDetailResponse, IProjectResponse, IStep,
  IStrapiAttributes,
  IStrapiData, ITag,
} from '@redux/types';
import {call, put, takeEvery} from 'redux-saga/effects';
import api from '@api/index';
import * as actions from './actions';
import {
  EProjectActionType,
  IProject,
  IProjectGetAction, IProjectPage,
  IProjectPageResponse,
  IProjectsPageGetAction
} from '@redux/projects/types';
import {generateDetail, generateProject, generateStep, generateStrapiQuery} from '@common/functions';
import moment from 'moment';
import {YEAR_FORMAT} from '@common/constants';
import {ICaseListItem} from '@common/types';


function* getProjectsPage({payload}: IProjectsPageGetAction) {
  try {
    const response: IStrapiData<IStrapiAttributes<IProjectPageResponse>> = yield call(() => api.get('/project-page', {
      query: {
        populate: [
          'image,tags,meta,',
          'projects.cardType,projects.image,projects.imageMobile,projects.tags,projects.meta.onlyPreview,projects.icon,',
          'cases.case,cases.case.tags,cases.project,cases.project.tags,cases.project.description',
        ].join(''),
        locale: payload.locale
      }}
    ))

    const data = response.data.attributes
    const gridProject = generateProject(data?.projects || [])

    yield put(actions.setProjects({
      projectsPage: {
        ...data,
        tags: data.tags.data.map((s: { attributes: ITag }) => ({
          ...s.attributes
        })),
        projects: gridProject,
      } as IProjectPage,
      projectsWithImages: gridProject,
      projectsSortByDate: data?.cases?.map((item) => {
        const project = item?.project?.data?.attributes
        return {
          title: item?.case?.title || project?.title || '',
          note: item?.case?.note || project?.note || '',
          text: item?.case?.text || [],
          description: project?.description || '',
          date: item?.case?.date ? moment(item.case?.date).format(YEAR_FORMAT) : project?.year ? moment(project?.year).format(YEAR_FORMAT) : null,
          tags: item?.case?.tags?.data?.map(t => t.attributes) || project?.tags?.data?.map(t => t.attributes) || []
        } as ICaseListItem
      }).sort((a, b) => (a?.date && b?.date && (a?.date > b?.date)) ? -1 : 1)
    }))
  } catch (e) {
    //console.log('Fetch failed, reason: ', e)
  }
}

function* getProjects({payload}: IProjectsPageGetAction) {
  try {
    const response: IStrapiData<IStrapiAttributes<IProjectResponse>[]> = yield call(() => api.get('/projects', {
      query: {
        populate: 'image,imageMobile,cardType,tags,icon',
        locale: payload.locale
      }}))
    const allProjects = generateProject(response)
    yield put(actions.setProjects({
      projects: allProjects,
      projectsWithImages: allProjects.filter(p => !!p?.image ||  !!p?.icon),
      projectsSortByDate: []
        // allProjects.filter(p => !p?.image).sort((a, b) => (a?.year && b?.year && (a?.year > b?.year)) ? -1 : 1)
    }))
  } catch (e) {
    //console.log('Fetch failed, reason: ', e)
  }
}

function* getProject({payload}: IProjectGetAction) {
  const fields = ['title', 'slug']
  const query = generateStrapiQuery({
    fields,
    populate: 'deep,5',
    locale: payload?.locale || 'ru'
  })
  try {
    const response: IStrapiData<IStrapiAttributes<IProjectDetailResponse>> = yield call(() => api.get(`/projects/${payload.slug}`, {
      query
    }))
    const data = response.data.attributes
    const detail = !data.detail ? null : generateDetail(data.detail)
    const steps: IStep[] = generateStep(data.steps)

    const tags: ITag[] = data?.tags?.data?.map(t => t.attributes) ?? []
    const extendTags: ITag[] = data?.extendTags?.data?.map(t => t.attributes) ?? tags
    const similarProjectsCount = data.similarProjects.data?.length || 0
    let similarProjects = data.similarProjects.data.map((s) => {
      const {attributes} = s
      let cardType = attributes?.cardType?.data?.attributes?.type

      return {
        ...attributes,
        cardType: cardType ? cardType as CardType : null,
        image: attributes?.image?.data?.attributes ?? null,
        imageMobile: attributes?.imageMobile?.data?.attributes ?? null,
        icon: attributes?.icon?.data?.attributes ?? null,
        tags: attributes?.tags?.data?.map((s: { attributes: ITag }) => ({
          ...s.attributes
        })) ?? [],
        extendTags: attributes?.extendTags?.data?.map((s: { attributes: ITag }) => ({
          ...s.attributes
        })) ?? []
      }
    }) as IProject[]

    let fixSimilarProjects = similarProjects.map((p, index) => {
      let cardType = p.cardType
      let changeCardTypeForPreview = false

      switch (similarProjectsCount) {
        case 1:
          break;
        case 2: {
          if (similarProjects?.every(p => !p.cardType || p.cardType === 'rounded-right') && index === 1) {
            cardType = 'square'
            changeCardTypeForPreview = true
          }
          break;
        }
        default: {
          cardType = !cardType || cardType === 'rounded-right' ? 'square' : cardType
          changeCardTypeForPreview = true
        }
      }
      return {
        ...p,
        cardType,
        changeCardTypeForPreview
      }
    })

    let project: IProjectDetail = {
      ...data,
      detail,
      steps,
      similarProjects: fixSimilarProjects,
      tags: tags || [],
      extendTags: extendTags || []
    }
    yield put(actions.setProjects({
      project
    }))
  } catch (e) {
    yield put(actions.setProjects({
      project: null
    }))
  }
}

export default [
  takeEvery(EProjectActionType.GET_PROJECTS_PAGE, getProjectsPage),
  takeEvery(EProjectActionType.GET_PROJECTS, getProjects),
  takeEvery(EProjectActionType.GET_PROJECT, getProject),
]
