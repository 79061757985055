import {
  INewsPageResponse,
  IStrapiAttributes,
  IStrapiData,
} from '@redux/types';
import {call, put, takeEvery} from 'redux-saga/effects';
import api from '@api/index';
import {generateNews, generateStrapiQuery} from '@common/functions';
import * as actions from './actions';
import {ENewsActionType, INewsPageGetAction} from '@redux/news/types';

function* getNewsPage({payload}: INewsPageGetAction) {
  try {
    const {locale} = payload
    const query = generateStrapiQuery({
      populate: 'deep,4',
      locale
    })
    const response: IStrapiData<IStrapiAttributes<INewsPageResponse>> = yield call(() => api.get('/news-page', {query}))
    const data = response.data.attributes
    const {title, meta} = data
    const news = generateNews(data.news)
    yield put(actions.setNews({
      page: {title, meta},
      news
    }))
  } catch (e) {
    console.log('Fetch failed, reason: ', e)
  }
}


export default [
  takeEvery(ENewsActionType.GET_NEWS_PAGE, getNewsPage),
]
