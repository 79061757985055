import {
  IContacts,
  IStrapiAttributes,
  IStrapiData,
} from '@redux/types';
import {call, put, takeEvery} from 'redux-saga/effects';
import api from '@api/index';
import * as actions from './actions';
import {generateStrapiQuery} from '@common/functions';
import {EContactsActionType, IContactsGetAction} from '@redux/contacts/types';


function* getContacts({ payload }: IContactsGetAction) {
  try {
    const {locale} = payload
    const query = generateStrapiQuery({
      populate: '*',
      locale
    })
    const response: IStrapiData<IStrapiAttributes<IContacts>> = yield call(() => api.get(`/contact-page`, { query }))
    const contacts = response.data.attributes
    yield put(actions.setContacts({
      page: {
        meta: contacts.meta
      },
      contacts
    }))
  } catch (e) {
    console.log('Fetch failed, reason: ', e)
  }
}

export default [
  takeEvery(EContactsActionType.GET_CONTACTS, getContacts),
]
