import {Rule, RuleObject, StoreValue} from 'rc-field-form/es/interface';

export const TO_MB = 1024 * 1024

export const COOKIE_OPTIONS = {
  httpOnly: false,
  maxAge: 365 * 24 * 60 * 60
}

export const FORM_REQUIRED_RULE: Rule = {
  required: true,
  message: 'Обязательное поле!'
}

export const FORM_EMAIL_VALIDATE_RULE: Rule = {
  type: 'email',
  message: 'Email введен неверно!'
}

export const FORM_PHONE_VALIDATE_RULE: Rule  = {
  message: 'Телефон введен неверно!',
  validator: (_: RuleObject, value: StoreValue) => {
    const phoneSize = value?.replace(/\D/g, '')?.length
    if (phoneSize === 11) {
      return Promise.resolve()
    }
    return Promise.reject()
  }
}

export const YEAR_FORMAT = 'YYYY'

export const TIME_FORMAT = 'HH:mm'

export const DATE_FORMAT = `DD-MM-${YEAR_FORMAT}`

export const DATETIME_SHORT_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`

export const DATETIME_FORMAT = `${DATETIME_SHORT_FORMAT}:ss`

export const REQUEST_FORM_SOURCE = [
  {
    name: {
      ru: 'Рейтинги',
      en: 'Ratings',
    },
    value: 'id-1',
  },
  {
    name: {
      ru: 'Рекомендации',
      en: 'Recommendations',
    },
    value: 'id-2',
  },
  {
    name: {
      ru: 'Конференции',
      en: 'Conferences',
    },
    value: 'id-3',
  },
  {
    name: {
      ru: 'Публикации',
      en: 'Publications',
    },
    value: 'id-4',
  },
  {
    name: {
      ru: 'Соцсети',
      en: 'Social',
    },
    value: 'id-5',
  },
  {
    name: {
      ru: 'Другое',
      en: 'Other',
    },
    value: 'id-6',
  },
]

export const REQUEST_FORM_SOURCE_PARTNERS = [
  {
    name: {
      ru: 'Получить подряды',
      en: 'Contract agreement',
    },
    value: 'id-1',
  },
  {
    name: {
      ru: 'Получить лиды',
      en: 'Get leads',
    },
    value: 'id-2',
  },
  {
    name: {
      ru: 'Загрузить отдельных специалистов',
      en: 'Load individual specialists',
    },
    value: 'id-3',
  },
  {
    name: {
      ru: 'Продажа нерелевантных запросов в MobileUp',
      en: 'Selling irrelevant queries in MobileUp',
    },
    value: 'id-4',
  },
]

export const REQUEST_FORM_DELAY = 1000

export const STATIC_PATCH_LOCALE_QUERY = {
  fields: ['slug'],
  populate: 'meta,localizations',
  pagination: {
    page: 1,
    pageSize: 500
  }
}
