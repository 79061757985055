import {
  ETagActionType,
  ITagsGetAction,
  ITagsSetAction,
  ITagsState
} from '@redux/tags/types';


export const getTags = (): ITagsGetAction => ({
  type: ETagActionType.GET_TAGS
})

export const setTags = (payload: ITagsState): ITagsSetAction => ({
  type: ETagActionType.SET_TAGS,
  payload
})

