import {EAwardActionType, IAwardPageGetAction, IAwardSetAction, IAwardState} from '@redux/awards/types';
import {TLang} from '@common/types';
import {ILocale} from '@redux/types';

export const setAwards = (payload: IAwardState): IAwardSetAction => ({
  type: EAwardActionType.SET_AWARDS,
  payload
})

export const getAwardsPage = (payload: ILocale): IAwardPageGetAction => ({
  type: EAwardActionType.GET_AWARDS_PAGE,
  payload
})
