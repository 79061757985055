import {IAward, IEntityPage, ILocale} from '@redux/types';
import {TLang} from '@common/types';

export enum EAwardActionType {
  GET_AWARDS_PAGE = 'GET_AWARDS_PAGE',
  SET_AWARDS = 'SET_AWARDS',
}

export interface IAwardState {
  page?: IEntityPage
  awards?: IAward[]
  years?: string[]
}

export interface IAwardSetAction {
  type: EAwardActionType.SET_AWARDS,
  payload: IAwardState
}

export interface IAwardPageGetAction {
  type: EAwardActionType.GET_AWARDS_PAGE,
  payload: ILocale
}

export type IAwardActions = IAwardSetAction | IAwardPageGetAction
