import {
  IHomePageResponse,
  IStrapiAttributes,
  IStrapiData,
} from '@redux/types';
import {call, put, takeEvery} from 'redux-saga/effects';
import api from '@api/index';
import * as actions from './actions';
import {generateHomePage, generateStrapiQuery} from '@common/functions';
import {EHomeActionType, IHomePageGetAction} from '@redux/home/types';


function* getHomePage({ payload }: IHomePageGetAction) {
  try {
    const { locale } = payload
    const query = generateStrapiQuery({
      populate: [
        'slider.step,slider.solutions.slide.title.icon,',
        'services.services.meta,services.step,',
        'partners.step,partners.partners.logo,partners.partners.tag,',
        'cases.step,cases.projects.image,cases.projects.imageMobile,cases.projects.cardType,cases.projects.tags,cases.projects.icon,cases.projects.meta,',
        'products.step,products.products.image,products.products.type,',
        'awards.step,awards.awards,',
        'reviews.step,reviews.reviews.cardType,reviews.reviews.review.cardType,',
        'reviews.reviews.review.preview.video,reviews.reviews.review.preview.image',
        'banner.image,banner.imageMobile,banner.button,',
        'news.step,news.news.image,news.news.cardType,news.news.case,',
        'meta,statistics'
      ],
      locale
    })
    const response: IStrapiData<IStrapiAttributes<IHomePageResponse>> = yield call(() => api.get('/home-page', {
      query
    }))
    const data = response.data.attributes
    const {meta} = data
    const home = generateHomePage(data, locale)
    yield put(actions.setHome({
      page: meta ? {meta} : null,
      home
    }))
  } catch (e) {
    console.log('Fetch failed, reason: ', e)
  }
}


export default [
  takeEvery(EHomeActionType.GET_HOME, getHomePage)
]
