import {
  CardType, ICaseListItemResponse,
  IDetail,
  ILocale, IMetadata,
  IPage,
  IProjectDetail, IProjectResponse, IStrapiAttributes, IStrapiData,
  IStrapiImage,
  ITag
} from '@redux/types';
import {ICaseListItem} from '@common/types';


export enum EProjectActionType {
  GET_PROJECTS_PAGE = 'GET_PROJECTS_PAGE',
  GET_PROJECTS = 'GET_PROJECTS',
  SELECT_TAG = 'SELECT_TAG',
  GET_PROJECT = 'GET_PROJECT',
  SET_PROJECTS = 'SET_PROJECTS',
}


export interface IProject {
  id: string
  color?: string,
  cardType: CardType | null
  createdAt: string
  description: string
  year?: string
  image: IStrapiImage | null
  imageMobile?: IStrapiImage | null
  icon: IStrapiImage | null
  locale: string
  localizations: any
  note: string
  similarProjects: IProject[]
  slug: string
  tags: ITag[]
  extendTags?: ITag[]
  title: string
  updatedAt: string
  meta?: IMetadata | null,
  changeCardTypeForPreview?: boolean
}

export interface IProjectPage extends IPage {
  title: string,
  createdAt: string,
  updatedAt: string,
  tags: ITag[]
  projects: IProject[]
}

export interface IProjectPageResponse extends Omit<IProjectPage, 'projects' | 'tags'> {
  projects: IStrapiData<IStrapiAttributes<IProjectResponse>[]>
  tags: IStrapiData<IStrapiAttributes<ITag>[]>
  cases: {
    case: ICaseListItemResponse,
    project: IStrapiData<IStrapiAttributes<IProjectResponse>>
  }[]
}

export interface IProjectsState {
  projectsPage?: IProjectPage | null,
  projects?: IProject[],
  projectsWithImages?: IProject[],
  projectsSortByDate?: ICaseListItem[],
  isFetchingLoading?: boolean,
  project?: IProjectDetail | null,
  selectedTagSlug?: string | null
}

export interface IProjectsPageGetAction {
  type: EProjectActionType.GET_PROJECTS_PAGE,
  payload: ILocale
}

export interface IProjectsGetAction {
  type: EProjectActionType.GET_PROJECTS,
  payload: ILocale
}

export interface ISelectProjectsTagAction {
  type: EProjectActionType.SELECT_TAG,
  payload: string | null
}

export interface IProjectGetAction {
  type: EProjectActionType.GET_PROJECT,
  payload: IDetail
}

export interface IProjectsSetAction {
  type: EProjectActionType.SET_PROJECTS,
  payload: IProjectsState
}

export type IProjectsActions = IProjectsGetAction | IProjectsSetAction | IProjectsPageGetAction | IProjectGetAction | ISelectProjectsTagAction
