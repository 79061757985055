import {
  IStep,
  IStrapiAttributes,
  IStrapiData,
} from '@redux/types';
import {call, put, takeEvery} from 'redux-saga/effects';
import api from '@api/index';
import * as actions from './actions';
import {EVacancyActionType, IVacancyGetAction} from '@redux/vacancies/types';
import {generateStep, generateStrapiQuery} from '@common/functions';


function* getVacanciesPage() {
  try {
    const query = generateStrapiQuery({
      populate: [
        'detail.media,detail.embed,meta,',
        'steps.placement,steps.step,steps,steps.vacancies.meta,steps.embed.video,steps.embed.caption'
      ].join('')
    })
    const response: IStrapiData<IStrapiAttributes<any>> = yield call(() => api.get('/vacancy-page', {
      query
    }))
    const data = response.data.attributes
    const steps: IStep[] = generateStep(data.steps)
    yield put(actions.setVacancies({
      vacanciesPage: {
        ...data,
        steps
      }
    }))
  } catch (e) {
    console.log('Fetch failed, reason: ', e)
  }
}

function* getVacancy({ payload }: IVacancyGetAction) {
  try {
    const query = generateStrapiQuery({
      populate: 'deep'
    })
    const response: IStrapiData<IStrapiAttributes<any>> = yield call(() => api.get(`/vacancies/${payload.slug}`, {
      query
    }))
    const data = response.data.attributes
    const steps: IStep[] = generateStep(data.steps)
    yield put(actions.setVacancies({
      vacancy: {
        ...data,
        steps
      }
    }))
  } catch (e) {
    yield put(actions.setVacancies({
      vacancy: null
    }))
    //console.log('Fetch failed, reason: ', e)
  }
}

export default [
  takeEvery(EVacancyActionType.GET_VACANCIES_PAGE, getVacanciesPage),
  takeEvery(EVacancyActionType.GET_VACANCY, getVacancy),
]
