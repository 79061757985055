import {
  EVacancyActionType,
  IVacanciesPageGetAction,
  IVacanciesSetAction,
  IVacanciesState, IVacancyGetAction
} from '@redux/vacancies/types';

export const getVacanciesPage = (): IVacanciesPageGetAction => ({
  type: EVacancyActionType.GET_VACANCIES_PAGE
})

export const getVacancy = (slug: string): IVacancyGetAction => ({
  type: EVacancyActionType.GET_VACANCY,
  payload: {
    slug
  }
})

export const setVacancies = (payload: IVacanciesState): IVacanciesSetAction => ({
  type: EVacancyActionType.SET_VACANCIES,
  payload
})

